'use client';

import { startCase } from 'lodash';

import { useSigninFormConfigContext } from '@/features/authentication/signin/providers';

import { BaseHeader } from '@/ui/components/header';
import { HeaderBackIconButton } from '@/ui/components/header/components';
import { Page } from '@/ui/components/page';
import { PasscodeVerificationFormWithKeypad } from '@/ui/components/passcode-verification-form-with-keypad';

import { useDeviceDetect } from '@/shared/hooks';
import { useAccountLog } from '@/shared/hooks/use-accounts-log';
import { ROUTES } from '@/shared/routes';

import { useRouter } from '@/lib/navigation';
import { cn } from '@/lib/utils';

import { useSignInForm } from '../hooks/use-sign-in-form';
import { useInitRecoverPasswordForm } from '../passcode-recovery/hooks/use-init-recover-password-form';

export function PasscodeVerification() {
  const { isTablet } = useDeviceDetect();
  const { handleInitRecoverPasscode } = useInitRecoverPasswordForm();
  const router = useRouter();

  const { config } = useSigninFormConfigContext();
  const { getProfile: getProfileFromAccountLog } = useAccountLog();
  const profileCookie = getProfileFromAccountLog(config?.data?.username);

  const { title, subtitle, passcodeLength = 6 } = config.passcodeVerification;

  const { passcode, handleSignIn, isLoading, error, setError } = useSignInForm({
    passcodeLength,
  });

  const isValidSession =
    config.data.username || (config.data.idToken && config.data.socialProvider);

  if (!isValidSession) {
    router.push(ROUTES.SIGN_IN.INDEX);
    return null;
  }

  const { firstName, lastName, profileImageUrl } = profileCookie || {};

  const name = [firstName, lastName].map(startCase).join(' ');

  return (
    <>
      <BaseHeader
        className="py-units-unit10 px-units-unit16 md:px-fluid-header-px md:py-fluid-header-py"
        leftElement={<HeaderBackIconButton onClick={router.back} />}
      />
      <Page
        className={cn(`flex-grow`, {
          'md:my-10': isTablet,
        })}
      >
        <PasscodeVerificationFormWithKeypad
          value={passcode}
          passcodeLength={passcodeLength}
          title={title}
          subtitle={subtitle}
          recoverPasscodeFn={() =>
            handleInitRecoverPasscode({ phoneNumber: config.data.username })
          }
          user={{ name, profileImageUrl }}
          onChange={handleSignIn}
          cancelUrl={ROUTES.SIGN_IN.INDEX}
          inputLabel="Passcode"
          isButtonLoading={isLoading}
          error={error}
          resetError={() => setError('')}
        />
      </Page>
    </>
  );
}
