'use client';

import { Form, FormState, LinkButton } from '@kamona/components';
import { SubmitHandler } from 'react-hook-form';

import { PageSubtitle, PageTitle } from '@/ui/components/page';

import { useDeviceDetect } from '@/shared/hooks';

import { cn } from '@/lib/utils';

import { PasscodeInputWrapper } from '../passcode-input-wrapper';

export interface PasscodeVerificationFormWithKeypadProps {
  value: string;
  title: string;
  subtitle?: string | React.ReactNode;
  isKnownUser?: boolean;
  passcodeLength: number;
  onChange: (value: string) => void;
  onResend?: () => void;
  recoverPasscodeLink?: string;
  recoverPasscodeFn?: () => void;
  user?: {
    name: string;
    profileImageUrl?: string;
  };
  cancelUrl?: string;
  inputLabel?: string;
  inputMasked?: boolean;
  isButtonLoading?: boolean;
  error?: string;
  resetError?: () => void;
  forceTitleLeftAligned?: boolean;
  formClass?: string;
}

export function PasscodeVerificationFormWithKeypad({
  title,
  subtitle,
  passcodeLength,
  recoverPasscodeFn,
  onChange,
  onResend,
  inputLabel: label,
  inputMasked: masked = true,
  isButtonLoading = false,
  error,
  resetError,
  forceTitleLeftAligned,
  formClass,
}: Readonly<PasscodeVerificationFormWithKeypadProps>) {
  const { isTabletOrMobile, isMobile, isTablet } = useDeviceDetect();
  const handleSubmit: SubmitHandler<Record<string, string>> = ({
    passcode,
  }) => {
    onChange(passcode);
  };

  const renderRecoverPasscodeLink = () => {
    return (
      recoverPasscodeFn && (
        <LinkButton
          aria-label="recover-passcode-link"
          onClick={recoverPasscodeFn}
          className={{
            root: cn(
              '!text-moniepoint-primary-l7 font-medium text-body-default no-underline !justify-start',
              {
                '!absolute top-[100%]': isTablet,
              },
            ),
          }}
        >
          Forgot your passcode?
        </LinkButton>
      )
    );
  };

  return (
    <Form
      className={cn(
        'gap-units-unit32 flex flex-col flex-grow justify-start w-full relative',
        {
          'justify-between': isMobile,
          'justify-center gap-units-unit48': isTablet,
          'items-center': isTabletOrMobile && !forceTitleLeftAligned,
          'md:gap-units-unit56': !isTabletOrMobile,
        },
        formClass,
      )}
      onSubmit={handleSubmit}
    >
      <PageTitle
        className="my-units-unit16"
        align={isTabletOrMobile && !forceTitleLeftAligned ? 'center' : 'left'}
        text={title}
        subtitle={
          <PageSubtitle
            align={
              isTabletOrMobile && !forceTitleLeftAligned ? 'center' : 'left'
            }
          >
            {subtitle}
          </PageSubtitle>
        }
      />
      <FormState>
        {(formState) => (
          <PasscodeInputWrapper
            label={label ?? '6 digit passcode'}
            isButtonLoading={isButtonLoading}
            error={error}
            resetError={resetError}
            handleSubmit={handleSubmit}
            renderRecoverPasscodeLink={renderRecoverPasscodeLink}
            passcodeLength={passcodeLength}
            name="passcode"
            buttonDisabled={!formState.isValid}
            inputMasked={masked}
            pattern={{
              value: /^\d{6}$/,
              message: 'Enter 6 digit passcode',
            }}
            onResend={onResend}
            showValidationMessage={false}
            pinInputVariant={'filled'}
            pinInputSize={'large'}
            isPinInputClearable={false}
            pinInputWrapperClassName="gap-y-units-unit24"
            pinInputValidationMode="onSubmit"
          />
        )}
      </FormState>
    </Form>
  );
}
