import { Column } from '@kamona/components';
import { debounce, noop } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { useDeviceDetect } from '@/shared/hooks';

import { MobilePhoneKeypad } from '../mobile-phone-keypad';
import { PasscodeCircleInput } from './passcode-circle-input';

interface PasscodeInputWithKeypadProps {
  length: number;
  name: string;
  showValidationMessage?: boolean;
  label?: string;
  onSubmit: SubmitHandler<Record<string, string>>;
  renderRecoverPasscodeLink?: () => React.ReactNode;
  keypadDisabled?: boolean;
  helperText?: string;
  errorText?: string;
  resetErrorText?: () => void;
  dotAppearance?: 'default' | 'error';
  resetValue?: boolean;
}
const PasscodeInputWithKeypad: FC<PasscodeInputWithKeypadProps> = (props) => {
  const { isTablet } = useDeviceDetect();
  const [inputValue, setInputValue] = useState('');
  const { length, label, resetErrorText } = props;
  const [dotAppearance, setDotAppearance] = useState(props.dotAppearance);
  const [errorText, setErrorText] = useState(props.errorText);

  const debouncedSubmitHandler = debounce(props.onSubmit, 300);

  const submitHandler = () => {
    debouncedSubmitHandler({ [props.name]: inputValue });
  };

  useEffect(() => {
    if (inputValue.length === length) {
      submitHandler();
    }
  }, [inputValue]);

  useEffect(() => {
    if (props.resetValue) {
      setInputValue('');
    }
  }, [props.resetValue]);

  useEffect(() => {
    if (props.errorText && props.errorText !== '') {
      setErrorText(props.errorText);
    }
  }, [props.errorText]);

  useEffect(() => {
    setDotAppearance(props.dotAppearance);
  }, [props.dotAppearance]);

  const onButtonClick = (value: string) => {
    if (inputValue.length < length) {
      setDotAppearance('default');
      setErrorText('');
      setInputValue(inputValue + value);
      if (resetErrorText) {
        resetErrorText();
      }
    }
  };
  const onBackspaceKeyPressed = () => {
    setInputValue(inputValue.slice(0, -1));
  };
  return (
    <>
      <PasscodeCircleInput
        length={length}
        size="small"
        value={inputValue}
        label={label}
        appearance={dotAppearance}
        helperText={props.helperText}
        errorText={errorText}
      />
      <Column align={'center'} justify={'center'} gapY={'units-unit16'}>
        <MobilePhoneKeypad
          {...props}
          onNumberKeyPressed={onButtonClick}
          onBackspaceKeyPressed={onBackspaceKeyPressed}
          onSubmit={noop}
          appearance="neutral"
          showBackspace={inputValue.length > 0}
          disabled={props.keypadDisabled}
          className="mb-units-unit24 mt-units-unit16 gap-units-unit24"
        />
        {!isTablet && props?.renderRecoverPasscodeLink?.()}
      </Column>
      {isTablet && props?.renderRecoverPasscodeLink?.()}
    </>
  );
};

export default PasscodeInputWithKeypad;
