'use client';

import { notification } from '@kamona/components';
import { parsePhoneNumber } from '@kamona/utils-v2';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useSigninFormConfigContext } from '@/features/authentication/signin/providers';

import { ROUTES } from '@/shared/routes';
import { CountryCode, type PhoneInfoValue } from '@/shared/types';

import { useAnalytics } from '@/lib/analytics-service';
import { useRouter } from '@/lib/navigation';

export interface PhoneNumberFormInputs {
  phone: PhoneInfoValue;
}

export const usePhoneNumberForm = () => {
  const analytics = useAnalytics();
  const router = useRouter();
  const { config, setConfig } = useSigninFormConfigContext();

  const methods = useForm({
    defaultValues: {
      phoneNumber: config?.data?.username ?? '',
    },
  });

  const handleSignin: SubmitHandler<Record<string, any>> = (data) => {
    const phoneInfo = parsePhoneNumber(data?.phoneNumber);

    if (!phoneInfo) {
      return notification.error({
        title: 'Invalid phone number',
        position: 'top-center',
        variant: 'filled',
      });
    }

    const {
      internationalNumber,
      countryCode: selectedCountry,
      callingCode: countryCode,
      nationalNumber: number,
    } = phoneInfo;

    setConfig({
      ...config,
      passcodeVerification: {
        ...config.passcodeVerification,
        title: 'Passcode',
        subtitle: 'Enter your secure 6-digit passcode.',
      },
      data: {
        username: internationalNumber,
        isSSO: false,
        phoneInfo: {
          selectedCountry: selectedCountry as CountryCode,
          countryCode,
          number,
        },
      },
    });

    analytics.track({
      name: 'login_initiation',
      data: {},
    });

    router.push(ROUTES.SIGN_IN.$PASSCODE_VERIFICATION(ROUTES.SIGN_IN.INDEX));
  };

  return {
    initialValues: { phoneNumber: config?.data?.username ?? '' },
    handleSignin: methods.handleSubmit(handleSignin),
    methods,
  };
};
