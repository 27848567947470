import { Column, Row, XSmallBody } from '@kamona/components';
import React, { FC } from 'react';

import { useDeviceDetect } from '@/shared/hooks';

import { cn } from '@/lib/utils';

interface PasscodeCircleInputProps {
  length: number;
  size: 'small' | 'medium';
  label?: string;
  value: string;
  helperText?: string;
  errorText?: string;
  appearance?: 'default' | 'error';
}

export const PasscodeCircleInput: FC<PasscodeCircleInputProps> = (props) => {
  const {
    length,
    value,
    appearance = 'default',
    label,
    helperText,
    errorText,
  } = props;

  const { isTablet } = useDeviceDetect();

  return (
    <Column
      gapY={'units-unit12'}
      className={cn({ 'justify-center': isTablet })}
    >
      <Row className="justify-center gap-4">
        {Array.from({ length }).map((_, index) => {
          const isActive = index < value.length;
          return (
            <PasscodeCircleInputItem
              key={index}
              size={props.size}
              appearance={
                isActive && appearance !== 'error' ? 'active' : appearance
              }
            />
          );
        })}
      </Row>

      <XSmallBody
        className={cn(
          'text-content-subtext-default mx-auto max-w-[80%] text-center',
          {
            'text-feedback-danger-filled-default': appearance === 'error',
          },
        )}
      >
        {label ?? helperText ?? errorText}
      </XSmallBody>
    </Column>
  );
};

interface PasscodeCircleInputItemProps {
  size: 'small' | 'medium';
  appearance: 'default' | 'error' | 'active';
}
const PasscodeCircleInputItem: FC<PasscodeCircleInputItemProps> = ({
  size,
  appearance,
}) => {
  const sizeMap = {
    small: 'w-4 h-4',
    medium: 'w-6 h-6',
  };

  const bgMap = {
    default: 'bg-inset-default',
    error: 'bg-feedback-danger-ghost-default',
    active: 'bg-content-subtext-alternative',
  };

  return (
    <div className={cn('rounded-full', sizeMap[size], bgMap[appearance])} />
  );
};
