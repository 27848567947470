import { KeypadProps } from '@kamona/components';
import { UnDelete } from '@kamona/icons-v2';
import { FC } from 'react';

import { cn } from '@/lib/utils';

import { MobilePhoneKeypadItem } from './mobile-phone-keypad-item';

interface MobilePhoneKeypadProps extends KeypadProps {
  showBackspace?: boolean;
  disabled?: boolean;
}
export const MobilePhoneKeypad: FC<MobilePhoneKeypadProps> = (props) => {
  return (
    <div className={cn('grid grid-cols-3 gap-units-unit16', props.className)}>
      {Array.from({ length: 9 }).map((_, index) => (
        <MobilePhoneKeypadItem
          key={index + 1}
          value={index + 1}
          appearance={props.appearance}
          onClick={() => props.onNumberKeyPressed?.(`${index + 1}`)}
          disabled={props.disabled}
          className="size-units-unit80 typography-header-default"
        />
      ))}
      {/* {dummy div to make grid} */}
      <div />
      <MobilePhoneKeypadItem
        value={'0'}
        appearance={props.appearance}
        onClick={() => props.onNumberKeyPressed?.(`0`)}
        disabled={props.disabled}
        className="size-units-unit80 typography-header-default"
      />
      {props.showBackspace && (
        <MobilePhoneKeypadItem
          aria-label="Backspace"
          appearance={props.appearance}
          value={<UnDelete size="units-unit24" />}
          onClick={() => props.onBackspaceKeyPressed?.()}
          disabled={props.disabled}
          className="size-units-unit80 typography-header-default"
        />
      )}
    </div>
  );
};
