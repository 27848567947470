'use client';

import { Body, Typography } from '@kamona/components';
import { useEffect } from 'react';

import { Flex, FlexProps } from '@/ui/components/flex';
import ClockSvg from '@/ui/svgs/clock-svg';
import { KamonaSpinner } from '@/ui/svgs/kamona-spinner';

import { DEFAULT_RESEND_IN_SECONDS } from '@/lib/constants';

import { formatTime } from './otp-input-utils';
import { useTimer } from './use-timer-hook';

interface ResendCodeProps extends FlexProps {
  onResend: () => void;
  enableResendInSeconds?: number;
  isLoading?: boolean;
}

export function ResendCode({
  onResend,
  enableResendInSeconds = DEFAULT_RESEND_IN_SECONDS,
  isLoading,
  ...props
}: Readonly<ResendCodeProps>) {
  const { timeLeft, setTimeLeft } = useTimer(enableResendInSeconds);

  const handleResend = () => {
    onResend();
  };

  useEffect(() => {
    if (enableResendInSeconds) setTimeLeft(enableResendInSeconds);
  }, [enableResendInSeconds, isLoading]);

  return (
    <Flex
      gap="units-unit4"
      className="text-body-default items-center"
      {...props}
    >
      <ClockSvg />
      <Typography as="span" weight="semibold">
        Didn&apos;t get code?
      </Typography>{' '}
      {isLoading && <KamonaSpinner className="size-units-unit16" />}
      {!isLoading && (
        <>
          {timeLeft > 0 ? (
            <>
              Resend in{' '}
              <Body weight="semibold" color="moniepoint-primary-l7">
                {formatTime(timeLeft)}
              </Body>
            </>
          ) : (
            <Body
              as="button"
              weight="semibold"
              color="moniepoint-primary-l7"
              onClick={handleResend}
              aria-label="resend-otp-button"
              type="button"
            >
              Resend
            </Body>
          )}
        </>
      )}
    </Flex>
  );
}
