'use client';

import { Button, Column } from '@kamona/components';
import { FormProvider } from 'react-hook-form';

import { countryOptionsForPhoneInput } from '@/ui/components/phone-input/phone-input-data';
import { PhoneNumberInput } from '@/ui/components/phone-number-input';

import { usePhoneNumberForm } from '../hooks/use-phone-number-form';

export const SigninForm = ({ ...props }) => {
  const { handleSignin, initialValues, methods } = usePhoneNumberForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSignin} className="flex flex-grow justify-start">
        <Column
          className="w-full flex-grow justify-between md:flex-grow-0 gap-y-units-unit40 md:justify-start"
          {...props}
        >
          <Column as="fieldset" gap="units-unit20">
            <PhoneNumberInput
              defaultValue={initialValues.phoneNumber}
              variant="filled"
              inputMode="tel"
              label="Mobile number"
              defaultCountry="GB"
              name="phoneNumber"
              required={{ value: true, message: 'Phone number is required' }}
              size="large"
              supportedCountries={countryOptionsForPhoneInput}
              isCountryDisabled
            />
          </Column>
          <Column gap="units-unit16">
            <Button
              size="medium"
              type="submit"
              variant="filled"
              appearance="primary"
              disabled={!methods.formState.isValid}
            >
              Continue
            </Button>
          </Column>
        </Column>
      </form>
    </FormProvider>
  );
};
