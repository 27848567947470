import { PadItemProps } from '@kamona/components';
import { defineVariants, useBlueprint } from '@kamona/styled-system';
import React, { FC, useState } from 'react';

import { cn } from '@/lib/utils';

interface MobilePhoneKeypadItemProps extends PadItemProps {}

const padItemVariants = defineVariants({
  base: 'typography-body-x-large-untrimmed flex size-units-unit64 cursor-pointer items-center justify-center rounded-radius-full font-medium outline-none',
  variants: {
    appearance: {
      flat: 'bg-transparent text-feedback-neutral-ghost-text',
      neutral:
        'bg-feedback-neutral-ghost-default text-feedback-neutral-ghost-text ',
    },
    disabled: {
      true: 'pointer-events-none cursor-not-allowed opacity-40',
    },
  },
  defaultVariants: {
    appearance: 'neutral',
    disabled: false,
  },
});

export const MobilePhoneKeypadItem: FC<MobilePhoneKeypadItemProps> = (
  props,
) => {
  const blueprint = useBlueprint(props, padItemVariants);
  const [isActive, setIsActive] = useState(false);

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsActive(true);
    if (props.onClick) {
      props.onClick(e);
    }
    if ('vibrate' in navigator) {
      navigator.vibrate(10);
    }
    setTimeout(() => {
      setIsActive(false);
    }, 100);
  };
  return (
    <button
      {...(blueprint.ownProps as any)}
      type="button"
      disabled={props.disabled}
      onClick={onClick}
      className={cn(
        blueprint.classNames,
        isActive && 'bg-feedback-neutral-ghost-pressed',
      )}
    >
      {props.value}
    </button>
  );
};
